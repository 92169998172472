<template>
  <v-container fluid class="d-flex justify-center" v-if="!isLoading">
    <div class="d-flex flex-column" style="min-width: 80%">
      <template v-if="characters && characters.length > 0">
        <h1>Your Characters</h1>
        <CharacterCard
          v-for="character in characters"
          :key="character.id"
          :character="character"
        />
      </template>
      <template v-else>
        <v-btn color="success" @click="create_character"
          >Make a Character</v-btn
        >
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

import store from "@/store";

import { FETCH_CHARACTERS, CREATE_CHARACTER } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

import CharacterCard from "@/components/CharacterCard.vue";

export default Vue.extend({
  name: "Characters",
  components: { CharacterCard },
  computed: {
    ...mapGetters(["isLoading", "characters"]),
  },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([store.dispatch(FETCH_CHARACTERS)]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    create_character: () => {
      store.commit(BEGIN_LOADING);
      Promise.all([store.dispatch(CREATE_CHARACTER)]).then(() => {
        store.commit(END_LOADING);
      });
    },
  },
});
</script>
