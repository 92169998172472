<template>
  <div class="topic-container mt-2 mb-2">
    <v-sheet shaped class="topic-body pa-2">
      <div class="text-h5">{{ character.name }}</div>
      <div class="text-subtitle-1 font-weight-light">
        Created On {{ new Date(character.created_on * 1000).friendlyString() }}
      </div>
    </v-sheet>
    <v-sheet
      rounded
      elevation="6"
      class="topic-details blue-grey darken-4 font-weight-light pa-2 text-caption"
      style="opacity: 0.8"
    >
      <div>{{ character.id }}</div>
    </v-sheet>
    <div class="topic-actions text-h6">
      <v-btn color="secondary" disabled style="margin-right: 5px"
        >Forgot Password?</v-btn
      >
      <a href="http://play.torchship.org/player-client/">
        <v-btn color="primary"> Log In </v-btn>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharacterCard",
  props: {
    character: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.topic-container {
  display: grid;
  grid-template-columns: 3fr 1.2fr;
  grid-template-rows: 1em 1fr 2em;
  min-height: 10em;
}

.topic-details {
  grid-row: 1 / span 2;
  grid-column: 2;
  margin-right: 2em;
  max-height: 3em;
}

.topic-body {
  grid-row: 2 / span 2;
  grid-column: 1 / 3;
}

.topic-actions {
  grid-row: 3;
  grid-column: 1 / span 2;
  margin-right: 2em;
  text-align: right;
}
</style>
